import * as React from 'react';
import { sortPartialDate, formatPartialDate, isPartialDate, partialDateToValue } from 'neuro-utils';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { useIntl } from 'react-intl';

const NotesDash = ({ documents }: IOwnProps): JSX.Element | undefined => {
  const notesDocs = documents.filter((d) => d._type === 'notes');
  const sortedArr = notesDocs
    ?.sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  const currentNinmtTreatmentPeriod = documents
    ?.filter((d) => d._type === 'ninmtTreatmentPeriod')
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date))[0];

  const notesCurrentPeriod = sortedArr.filter(
    (n) =>
      partialDateToValue(n.date) >= partialDateToValue(currentNinmtTreatmentPeriod && currentNinmtTreatmentPeriod.date),
  );

  const { formatMessage } = useIntl();
  return (
    <TileContentMaker
      type="specificListMultipleRows"
      data={notesCurrentPeriod.map((d) => ({
        key: d._id,
        date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
        title: (
          <div>
            {formatMessage({ id: 'notes.neuroModulationOtherDetails' })}
            <div
              style={{
                fontSize: '1.6rem',
                fontWeight: 600,
                maxHeight: '16rem',
                overflow: 'hidden',
                display: '-webkit-box', // Just to display ellipsis after multiple rows
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 8,
                whiteSpace: 'pre-wrap',
              }}
            >
              {d.neuroModulationOtherDetails || '-'}
            </div>
          </div>
        ),
      }))}
      itemsCanFitAmount={1}
    />
  );
};

interface IOwnProps {
  documents: Array<INotes>;
}

export default NotesDash;
