import * as React from 'react';

import DocumentLoader from '../containers/DocumentLoader';
import DashboardCreator, { IContents } from './_Dashboard';

import { capitalize } from '../utility/string';
import ErrorContainer from '../containers/ErrorContainer';

class DashClass extends React.Component<{ s: string }> {
  render(): React.ReactNode {
    return (
      <DocumentLoader name={this.props.s}>{require('./' + capitalize(this.props.s) + '/Dash/').default}</DocumentLoader>
    );
  }
}

// Fetch every dashboard element enabled
const dashboardElements = (sections: string[]): { contents: IContents } => {
  const elements: { contents: IContents } = { contents: {} };
  sections.forEach((s) => {
    // Get contents
    elements.contents = {
      ...elements.contents,
      [s]: <DashClass s={s} />,
    };
  });
  return elements;
};

// Need to export React component, not element
export const Dashboard = (sections: string[]): JSX.Element =>
  DashboardCreator({ sections, elements: dashboardElements(sections) });

// Fetch component for document routes and form editing tools and toolbar
class RouteClass extends React.Component<{ s: string }> {
  render(): React.ReactNode {
    return (
      <ErrorContainer>
        <DocumentLoader name={this.props.s}>
          {require('./' + capitalize(this.props.s) + '/Document/').default}
        </DocumentLoader>
      </ErrorContainer>
    );
  }
}

const routeElements = (sections: string[]): { element: JSX.Element; path: string }[] => {
  const routes: Array<{ element: JSX.Element; path: string }> = [];
  sections.forEach((s) => {
    routes.push({
      element: <RouteClass s={s} />,
      path: '/' + s,
    });
  });
  return routes;
};

export const documentRoutes = routeElements;
