import { filter, intersperse } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, sortPartialDate, sortTime } from 'neuro-utils';
import { styled } from '@mui/material';

import { ICapabilityContextProps, withCapabilities } from '../../../containers/CapabilityHandler';
import DocumentCreationButton from '../../../components/DocumentCreationButton';
import { FormContextProvider } from '../../../containers/FormContextHandler';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistoryRow from '../../../components/HistoryRow';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import HistoryRowControls from '../../../components/HistoryRowControls';
import HistoryTabs from '../../../components/HistoryTabs';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { Container, Item } from 'Components/Grid';
import Icon from 'Components/_NewElements/Icon';

import { sortDocuments } from '../../../utility/randomUtil';
import { assertCapabilities } from '../../../store/index';
import { useAppSelector as useSelector, useAppDispatch } from 'Store/index';
import { actions } from 'Store/documents/actions';
import PlatformCapabilities from '../../../config/capabilities';

import {
  filterMedications,
  findEndedMedications,
  findOtherTreatments,
  findPausedMedications,
  findPeriodsWithoutMedication,
  IFilterSettings,
} from '../utils';
import getEditingDocType from '../utils/getEditingDocType';

import MedicationForm from './Form/Medication';
import AdverseEffectForm from './Form/Medication/AdverseEffect';
import OtherTreatmentForm from './Form/OtherTreatment';
import PeriodWithoutMedicationForm from './Form/PeriodWithoutMedication';

import MedicationHistoryRowData from './HistoryRowData/Medication';
import OtherTreatmentsHistoryRowData from './HistoryRowData/OtherTreatment';
import PeriodWithoutMedicationHistoryRowData from './HistoryRowData/PeriodWithoutMedication';
import FilterComponent from './HistoryRowData/Medication/components/FilterComponent';
import { INeuroGrant } from 'neuro-data-structures';
import {
  AdverseEventIcon,
  AlertIcon,
  RegimenIconByType,
  SourceIconBySource,
} from './HistoryRowData/Medication/components/Common';
import { RegimenSmallInfo } from './HistoryRowData/Medication/components/Regimen/RegimenItems';
import findMatchingAdverseEvents from '../utils/findMatchingAdverseEvents';
import { atcCodesByPlatform } from './config';
import DropDownButton from 'Components/_NewElements/DropDownButton';
import { parkinsonInfusionMedicationUnfilledDose } from '../utils/parkinsonUnfilledFields';

const StyledMedicationHeader1 = styled('div')(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main,
  marginLeft: '-4.2rem', // Align first header with page left padding
}));
const StyledMedicationHeader2 = styled('div')(({ theme }) => ({
  fontWeight: 400,
  textAlign: 'center',
  color: theme.palette.grey[500],
  padding: '0 1rem',
  borderLeft: `0.1rem solid ${theme.palette.grey[300]}`,
}));

const StyledSmallHeader = styled('div')(({ theme }) => ({
  fontSize: '1.4rem',
  fontWeight: 400,
  padding: '0 1rem',
  color: theme.palette.grey[500],
}));

const StyledValues = styled('div')({
  fontSize: '1.4rem',
  fontWeight: 400,
  padding: '0 1rem',
});

const StyledWarningsArea = styled('div')({
  display: 'flex',
  '& > div': {
    marginRight: '1rem',
  },
});

const docType = (document: IControlProps & any): string | undefined | false => {
  if (document && document._type === 'procedure') {
    return document.code.code === '20720000'
      ? 'plasmaferesis'
      : document.code.code === '1269349006'
        ? 'stemCell'
        : undefined;
  }
  return document && document._type;
};

const medicationStatus = (med: IMedication): 'inUse' | 'toStart' | 'paused' | 'ended' => {
  if (findEndedMedications([med]).length > 0) return 'ended';
  if (findPausedMedications([med]).length > 0) return 'paused';
  if ([med].filter(filterMedications({ status: ['toStart'] })).length > 0) return 'toStart';
  return 'inUse';
};

const MedicationListStrengthFormatter = ({ strengths }: { strengths: IRegimenDefault['strengths'] }) => {
  const substances = strengths && strengths?.length > 0 && Object.keys(strengths[0]);
  const strsToSubstances = (substances || []).map((sub) => ({ [sub]: (strengths || []).map((str) => str[sub]) }));
  return !substances || substances.length === 0 ? (
    <></>
  ) : (
    <>
      {strsToSubstances.map((substr) => {
        const substance = Object.keys(substr)[0];
        const strengths = Object.values(substr)[0];
        return (
          <Container key={substance}>
            <Item
              xs={strengths.length === 3 ? 4 : strengths.length === 2 ? 5 : 6}
              style={{ textAlign: 'start' }}
              className="ellipsis-show-hover"
            >
              {substance}
            </Item>
            <Item xs={true} style={{ textAlign: 'end' }} className="ellipsis">
              {intersperse(', ', strengths)}
            </Item>
          </Container>
        );
      })}
    </>
  );
};

const Medications = ({
  documents,
  adverseEvents,
  startEdit,
  setMedID,
  collapseStatus,
  totalMedicationsLength,
  userOrg,
  otherMedicationOrgs,
}: IMedicationHistory): JSX.Element => {
  return (
    <>
      <div
        style={{
          boxSizing: 'border-box',
          paddingLeft: '4.2rem' /** Padding to align container with medication rows */,
        }}
      >
        <Container>
          <Item xs={5.5}>
            <StyledMedicationHeader1>
              <FormattedMessage
                id="medication.filteredMedicationsCount"
                values={{ count1: documents.length, count2: totalMedicationsLength }}
              />
            </StyledMedicationHeader1>
          </Item>
          <Item xs={2.2}>
            <StyledMedicationHeader2>
              <FormattedMessage id="medication.strength" />
            </StyledMedicationHeader2>
          </Item>
          <Item xs={true}>
            <StyledMedicationHeader2>
              <FormattedMessage id="medication.regimenDosage" />
            </StyledMedicationHeader2>
          </Item>
          <Item xs={0.6}>
            <StyledMedicationHeader2>
              <FormattedMessage id="medication.source" />
            </StyledMedicationHeader2>
          </Item>
        </Container>
      </div>
      {documents
        .sort((n1, n2) =>
          sortDocuments([
            { type: 'date', sortField: 'startDate' },
            { type: 'time', sortField: 'startTime' },
          ])(n1, n2),
        )
        .map((d) => {
          const status = medicationStatus(d);
          const startDate = d.usageStartDate
            ? formatPartialDate(d.usageStartDate)
            : d.startDate
              ? formatPartialDate(d.startDate)
              : '-';
          const endDate = d.endDate && formatPartialDate(d.endDate);

          const source =
            d._ownerOrg === userOrg && !(d._lockedFor && d._source)
              ? 'stellarq'
              : d._ownerOrg === userOrg && d._lockedFor && d._source
                ? 'integration'
                : d._ownerOrg !== userOrg
                  ? d._ownerOrg
                  : '';

          const firstRegimen = [...(d.regimen || [])]?.sort((r1, r2) => sortPartialDate(r2.date, r1.date))[0] as
            | Regimen
            | undefined;
          const matchedAdverseEffects = findMatchingAdverseEvents(adverseEvents, d._id);

          const alerts: Array<{ alertText: JSX.Element }> = [
            ...(parkinsonInfusionMedicationUnfilledDose([d])
              ? [{ alertText: <FormattedMessage id="alerts.parkinson.infusionMedicationUnfilledDose" /> }]
              : []),
          ];
          return (
            <BlockWrapper
              title={
                <Container>
                  <Item xs={5.5}>
                    <div className="ellipsis">
                      {d.medicationName && d.isClinicalStudy?.[0] === true ? (
                        <span style={{ textTransform: 'uppercase' }}>
                          <FormattedMessage id="medication.clinicalStudyMedication" />
                          {`: ${d.medicationName}`}
                        </span>
                      ) : d.medicationName ? (
                        d.medicationName.toUpperCase()
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="small">{d.dosageForm ? d.dosageForm : ''}</div>
                    <Container alignItems={'center'}>
                      <Item xs={true} style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                        <Icon
                          round
                          icon={
                            status === 'ended'
                              ? 'stop'
                              : status === 'paused'
                                ? 'pause'
                                : status === 'inUse'
                                  ? 'play'
                                  : 'next'
                          }
                          color={
                            status === 'ended' ? 'grayMedium' : status === 'paused' ? 'warningDefault' : 'appBlueMedium'
                          }
                        />
                        <div style={{ marginLeft: '0.5rem', fontSize: '1.6rem' }}>
                          {startDate}
                          {status === 'ended' && <span> &ndash; {endDate}</span>}
                        </div>
                        {(status === 'inUse' || status === 'paused') && (
                          <div style={{ marginLeft: '0.5rem', fontSize: '1.6rem', fontWeight: '400' }}>
                            <FormattedMessage id="medication.starting" />
                          </div>
                        )}
                      </Item>
                      <StyledWarningsArea>
                        {alerts.length > 0 && <Item>{AlertIcon(alerts)}</Item>}
                        {matchedAdverseEffects.length > 0 && (
                          <Item>{AdverseEventIcon(matchedAdverseEffects.length, true)}</Item>
                        )}
                      </StyledWarningsArea>
                    </Container>
                  </Item>
                  <Item xs={2.2}>
                    <StyledValues>
                      {Array.isArray(d.regimen) && d.regimen.length > 0 ? (
                        <MedicationListStrengthFormatter strengths={d.regimen[0].strengths} />
                      ) : undefined}
                    </StyledValues>
                  </Item>
                  <Item xs={true}>
                    <StyledValues>
                      <Container>
                        {firstRegimen?.regimenType && (
                          <Item xs={'auto'} style={{ marginRight: '1rem' }}>
                            {RegimenIconByType(firstRegimen.regimenType)}
                          </Item>
                        )}
                        {firstRegimen && (
                          <Item xs={true}>
                            <RegimenSmallInfo firstRegimen={firstRegimen} document={d} />
                          </Item>
                        )}
                      </Container>
                    </StyledValues>
                  </Item>
                  <Item xs={0.6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <StyledValues>{source && SourceIconBySource(source, otherMedicationOrgs)}</StyledValues>
                  </Item>
                </Container>
              }
              key={d._id}
              contentMargin={1}
              collapse={{
                open: collapseStatus.collapseID === d._id,
                collapseHandler: () => collapseStatus.setCollapseID(d._id),
              }}
              color={status === 'ended' ? 'gray' : status === 'paused' ? 'warning' : 'light'}
            >
              <MedicationHistoryRowData d={d} startEdit={startEdit} setMedID={setMedID} adverseEvents={adverseEvents} />
            </BlockWrapper>
          );
        })}
    </>
  );
};

const OtherTreatments = ({
  documents,
  startEdit,
}: {
  documents: (IMedicationOtherTreatment | IProcedure)[];
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
}): JSX.Element => {
  // Which document is shown as opened
  const [collapseID, setCollapseID] = React.useState<string>('');
  const setCollapseIDFunc = (id: string): void => {
    setCollapseID(id === collapseID ? '' : id);
  };

  const isOtherTreatment = (d: IMedicationOtherTreatment | IProcedure): d is IMedicationOtherTreatment =>
    d._type === 'otherTreatment';

  const status = (d: IMedicationOtherTreatment | IProcedure) =>
    isOtherTreatment(d) && d.hasEnded?.[0] === true ? 'ended' : 'started';

  const sortedRegimen = (d: IOtherTreatmentBasics & IVitaminD) =>
    d.type === 'vitaminD' && (d.regimen || []).length > 0
      ? [...(d.regimen || [])].sort((a, b) => sortPartialDate(b.date, a.date))
      : null;

  return (
    <React.Fragment>
      {documents.length > 0 ? (
        <>
          {documents
            .sort((n1, n2) => {
              const date1 = 'startDate' in n1 ? n1.startDate : 'date' in n1 ? n1.date : undefined;
              const date2 = 'startDate' in n2 ? n2.startDate : 'date' in n2 ? n2.date : undefined;
              return sortPartialDate(date2, date1);
            })
            .map((d) => (
              <BlockWrapper
                title={
                  <Container>
                    <Item xs={5.5} style={{ minHeight: '7rem' }}>
                      <div className="ellipsis">
                        {docType(d) === 'plasmaferesis' ? (
                          <FormattedMessage id={'medication.otherTreatment.opts.plasmaferesis'} />
                        ) : docType(d) === 'stemCell' ? (
                          <FormattedMessage id={'medication.otherTreatment.stemCellTransplant'} />
                        ) : (
                          'type' in d && <FormattedMessage id={'medication.otherTreatment.opts.' + d.type} />
                        )}
                      </div>
                      <Container alignItems={'center'}>
                        <Item xs={true} style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                          <Icon
                            round
                            icon={status(d) === 'ended' ? 'stop' : 'play'}
                            color={status(d) === 'ended' ? 'grayMedium' : 'appBlueMedium'}
                          />
                          <div style={{ marginLeft: '0.5rem', fontSize: '1.6rem' }}>
                            {isOtherTreatment(d) && d.startDate
                              ? formatPartialDate(d.startDate)
                              : 'date' in d && formatPartialDate(d.date)}
                            {isOtherTreatment(d) && status(d) === 'ended' && (
                              <span> &ndash; {d.endDate && formatPartialDate(d.endDate)}</span>
                            )}
                          </div>
                          {status(d) !== 'ended' && d._type !== 'procedure' && (
                            <div style={{ marginLeft: '0.5rem', fontSize: '1.6rem', fontWeight: '400' }}>
                              <FormattedMessage id="medication.starting" />
                            </div>
                          )}
                        </Item>
                      </Container>
                    </Item>
                    {'type' in d && d.type === 'vitaminD' && (
                      <Item xs={true}>
                        {sortedRegimen(d)?.[0].dosage && (
                          <Container>
                            <Item xs={3}>
                              <StyledSmallHeader>
                                <FormattedMessage id="medication.otherTreatment.dosage" />
                              </StyledSmallHeader>
                            </Item>
                            <Item>
                              <StyledValues>{sortedRegimen(d)?.[0].dosage}</StyledValues>
                            </Item>
                          </Container>
                        )}
                        {sortedRegimen(d)?.[0].frequency && (
                          <Container>
                            <Item xs={3}>
                              <StyledSmallHeader>
                                <FormattedMessage id="medication.otherTreatment.frequency" />
                              </StyledSmallHeader>
                            </Item>
                            <Item>
                              <StyledValues>
                                <FormattedMessage
                                  id={`medication.otherTreatment.opts.${sortedRegimen(d)?.[0].frequency}`}
                                />
                              </StyledValues>
                            </Item>
                          </Container>
                        )}
                        {sortedRegimen(d)?.[0].frequency === 'other' && sortedRegimen(d)?.[0].otherFrequency && (
                          <Container>
                            <Item xs={3}>
                              <StyledSmallHeader>
                                <FormattedMessage id="medication.otherTreatment.otherFrequency" />
                              </StyledSmallHeader>
                            </Item>
                            <Item>
                              <StyledValues className="ellipsis">{sortedRegimen(d)?.[0].otherFrequency}</StyledValues>
                            </Item>
                          </Container>
                        )}
                      </Item>
                    )}
                  </Container>
                }
                key={d._id}
                contentMargin={1}
                collapse={{
                  open: collapseID === d._id,
                  collapseHandler: () => setCollapseIDFunc(d._id),
                }}
                color={status(d) === 'ended' ? 'gray' : 'light'}
              >
                <OtherTreatmentsHistoryRowData d={d} startEdit={startEdit} />
              </BlockWrapper>
            ))}
        </>
      ) : null}
    </React.Fragment>
  );
};

const PeriodWithoutMedication = ({
  documents,
  startEdit,
}: {
  documents: IPeriodWithoutMedication[];
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
}): JSX.Element => {
  return (
    <React.Fragment>
      {documents.length > 0 ? (
        <>
          {documents
            .sort((n1, n2) =>
              sortDocuments([
                { type: 'date', sortField: 'endDate' },
                { type: 'time', sortField: 'startTime' },
              ])(n1, n2),
            )
            .map((d: IPeriodWithoutMedication) => (
              <HistoryRow
                headerText={<FormattedMessage id={'medication.periodWithoutMedication.title'} />}
                key={d._id}
                rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                controlsMargin={false}
              >
                <PeriodWithoutMedicationHistoryRowData d={d} />
              </HistoryRow>
            ))}
        </>
      ) : null}
    </React.Fragment>
  );
};

interface IMedicationHistory {
  documents: IMedication[];
  adverseEvents: IMedicationAdverseEvent[];
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
  setMedID: (id: string) => void;
  collapseStatus: ICollapseStatus;
  totalMedicationsLength: number;
  userOrg: string;
  otherMedicationOrgs: INeuroGrant[];
}

const Form = ({ editing, formData, consumeMedID, documents = [] }: IForm): JSX.Element => {
  const docType = getEditingDocType(documents, editing);
  const document = documents.find((d: { _id: string }) => d._id === editing);
  return (
    <React.Fragment>
      {docType === 'medication' && (
        <FormContextProvider context={{ formData, editing }}>
          <MedicationForm medication={document} />
        </FormContextProvider>
      )}
      {docType === 'adverseEffect' && (
        <AdverseEffectForm formData={formData} consumeMedID={consumeMedID} allDocuments={documents} />
      )}
      {(docType === 'otherTreatment' || docType === 'procedure') && (
        <FormContextProvider context={{ formData, editing, documents: [document] }}>
          <OtherTreatmentForm />
        </FormContextProvider>
      )}
      {docType === 'periodWithoutMedication' && (
        <FormContextProvider context={{ formData, editing }}>
          <PeriodWithoutMedicationForm />
        </FormContextProvider>
      )}
    </React.Fragment>
  );
};

interface IForm {
  editing: string;
  formData: IFormData<IMedicationAdverseEvent | IMedication>;
  consumeMedID: () => string | null;
  documents?: Array<IMedicationAdverseEvent | IMedication>;
}

const defaultFilterSettings: IFilterSettings = {
  status: ['inUse', 'paused', 'toStart', 'ended'],
  source: ['stellarq', 'integration'],
  regimenType: ['default', 'custom', 'single-dose', 'onDemand', 'static', 'other'],
};

const Medication = ({ documents, ...props }: IOwnProps): JSX.Element => {
  // Reference for adding adverse events, which are bound to medications by medication id
  const medIDReference = React.useRef<string | null>(null);

  const setMedIDRef = (id: string): void => {
    medIDReference.current = id;
  };

  const consumeMedIDRef = (): string | null => {
    const ret = medIDReference.current;
    medIDReference.current = null;
    return ret;
  };

  // Which document is shown as opened
  const [unCollapsedDoc, setUnCollapsedDoc] = React.useState<ICollapseStatus['collapseID']>('');
  const setCollapseID = (id: string): void => {
    setUnCollapsedDoc(id === unCollapsedDoc ? '' : id);
  };

  const platform = useSelector((s: IState) => s.session.platforms?.selected);

  const [selected, setSelected] = React.useState<number>(0);

  const visitreason = useSelector((s: IState) => s.session.data?.visitreason);

  const userOrg = useSelector((s: IState) => s.session.data?.orgid) || '';

  const otherMedicationOrgs = (useSelector((s: IState) => s.patient.grants) || []).filter(
    (grant) => grant.granteeOrg === userOrg,
  );
  const orgGroupArray = useSelector((s: IState) => s.session.orgGroupArray);
  const getDisplayName = (orgId: string) => (orgGroupArray || []).find((og) => og.orgId === orgId)?.displayName;

  const medicationDocs = (documents || []).filter((d) => d._type === 'medication') as IMedication[];

  const documentCreationButtonDisabled = assertCapabilities(
    [PlatformCapabilities.MEDICATION_CREATE],
    props.capabilityGroups ?? {},
  )
    ? false
    : assertCapabilities([PlatformCapabilities.MEDICATION_CREATE_RETROSPECTIVE], props.capabilityGroups ?? {}) &&
        visitreason === 'retrospectiveDataTransfer'
      ? false
      : true;

  const [filterSettings, setFilterSettings] = React.useState<IFilterSettings>(defaultFilterSettings);
  const filteredMedications = medicationDocs.filter(filterMedications(filterSettings));

  const setDefaultFilterSettings = () => setFilterSettings(defaultFilterSettings);

  const dispatch = useAppDispatch();

  const endPeriodWithoutMedication = () => {
    if (!Array.isArray(documents) || documents.length === 0) return;

    // Check for an MS medication document that was just saved
    const savedMsMedication = documents.find(
      (d) =>
        d._type === 'medication' &&
        // _editing is still true when this function fires
        d._editing === true &&
        isPartialDate((d as IMedication).startDate) &&
        atcCodesByPlatform['ms'].includes((d as IMedication).atc ?? ''),
    ) as IMedication | undefined;

    if (!savedMsMedication) return;

    const { startDate, pauses } = savedMsMedication;

    // An end date has to exist for the latest pause for it to be used in comparison
    const pauseEndDate =
      Array.isArray(pauses) &&
      pauses.length > 0 &&
      pauses
        .sort((a, b) => sortTime(b.startTime, a.startTime))
        .sort((a, b) => sortPartialDate(b.startDate, a.startDate))[0].endDate;

    // If a pause end date exists it should basically be used for comparison in all legitimate cases
    const comparisonDate = isPartialDate(pauseEndDate) ? pauseEndDate : startDate;

    // Check for latest active periods without medication that preceed MS medication document that was just saved
    const latestPeriodsWithoutMedication = documents.filter(
      (d) =>
        d._type === 'periodWithoutMedication' &&
        !isPartialDate((d as IPeriodWithoutMedication).endDate) &&
        sortPartialDate((d as IPeriodWithoutMedication).startDate, comparisonDate) <= 0,
    ) as IPeriodWithoutMedication[];

    if (latestPeriodsWithoutMedication.length === 0) return;

    // There could be multiple - modify all
    latestPeriodsWithoutMedication.forEach((period) => {
      actions.modifyField({ name: 'periodWithoutMedication', id: period._id }, period, {
        endDate: comparisonDate,
      })(dispatch);
    });
  };

  const document = (id: string) => (documents || []).find((d) => d._id === id);
  const hasStaticRegimenDocs = medicationDocs.some(
    (d) => d.regimen && d.regimen.some((reg: IRegimenBasics) => reg.regimenType === 'static'),
  );
  const hasOtherRegimenDocs = medicationDocs.some(
    (d) => d.regimen && d.regimen.some((reg) => reg.regimenType === 'other'),
  );

  return (
    <FormEditingHandler
      name="medication"
      documents={documents}
      toolbarProps={{
        saveButtonCallback: platform === 'ms' ? () => endPeriodWithoutMedication() : undefined,
      }}
    >
      {(editing, startEdit, formData): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'medication'}
            headerId={
              docType(document(editing)) === 'plasmaferesis'
                ? 'medication.otherTreatment.plasmaferesis'
                : docType(document(editing)) === 'stemCell'
                  ? 'medication.otherTreatment.stemCellTransplant'
                  : docType(document(editing)) === 'otherTreatment'
                    ? 'medication.otherTreatment.vitaminD'
                    : docType(document(editing)) === 'periodWithoutMedication'
                      ? 'medication.periodWithoutMedication.title'
                      : 'medication.title'
            }
            editing={editing}
            editButtons={
              <DocumentCreationButton
                name="medication"
                text={'medication.newMedication'}
                onClick={startEdit({})}
                disabled={documentCreationButtonDisabled}
                disabledTooltip={
                  documentCreationButtonDisabled ? <FormattedMessage id="medication.noCreateTooltip" /> : undefined
                }
                newStyle
              />
            }
            secondaryButtons={
              platform === 'ms'
                ? [
                    {
                      headerId: 'medication.otherTreatment.title',
                      infoText: 'medication.otherTreatment.description',
                      button: (
                        <DropDownButton
                          text="medication.otherTreatment.new"
                          height={4}
                          fontSize={16}
                          buttons={[
                            {
                              text: 'medication.otherTreatment.newDVitamin',
                              onClick: startEdit({}, 'otherTreatment', { type: 'vitaminD' }),
                            },
                            {
                              text: 'medication.otherTreatment.newPlasmaferesis',
                              onClick: startEdit({}, 'procedure', {
                                code: {
                                  codeSystem: 'http://snomed.info/sct',
                                  code: '20720000',
                                  description: 'Plasmafereesi',
                                },
                              }),
                            },
                            {
                              text: 'medication.otherTreatment.newStemCell',
                              onClick: startEdit({}, 'procedure', {
                                code: {
                                  codeSystem: 'http://snomed.info/sct',
                                  code: '1269349006',
                                  description: 'Kantasolusiirto',
                                },
                              }),
                            },
                          ]}
                        />
                      ),
                    },
                    {
                      headerId: 'medication.periodWithoutMedication.title',
                      infoText: 'medication.periodWithoutMedication.description',
                      button: (
                        <DocumentCreationButton
                          name="periodWithoutMedication"
                          text={'medication.periodWithoutMedication.new'}
                          onClick={startEdit({}, 'periodWithoutMedication')}
                          height={4}
                          fontSize={16}
                          newStyle
                        />
                      ),
                    },
                  ]
                : undefined
            }
          />
          {!editing && documents && documents.length > 0 ? (
            <HistoryTabs
              indexSelectionTools={{ index: selected, changeFunction: (i: number): void => setSelected(i) }}
              hideTabs={platform !== 'ms'}
            >
              {[
                {
                  title: 'medication.medications',
                  content: (
                    <>
                      <FilterComponent
                        filterSettings={filterSettings}
                        setFilterSettings={setFilterSettings}
                        otherMedicationOrgs={otherMedicationOrgs}
                        getOrgDisplayname={getDisplayName}
                        setDefaultFilterSettings={setDefaultFilterSettings}
                        showStaticRegimenIcon={hasStaticRegimenDocs}
                        showOtherRegimenIcon={hasOtherRegimenDocs}
                      />
                      <Medications
                        documents={filteredMedications}
                        adverseEvents={filter((d: IMedicationAdverseEvent) => d._type === 'adverseEffect', documents)}
                        startEdit={startEdit}
                        setMedID={setMedIDRef}
                        collapseStatus={{ collapseID: unCollapsedDoc, setCollapseID }}
                        totalMedicationsLength={medicationDocs.length}
                        userOrg={userOrg}
                        otherMedicationOrgs={otherMedicationOrgs}
                      />
                    </>
                  ),
                  count: filteredMedications.length,
                },
                ...(platform === 'ms'
                  ? [
                      {
                        title: 'medication.otherTreatment.otherTreatments',
                        content: <OtherTreatments documents={findOtherTreatments(documents)} startEdit={startEdit} />,
                        count: findOtherTreatments(documents).length,
                      },
                      {
                        title: 'medication.periodWithoutMedication.periodWithoutMedications',
                        content: (
                          <PeriodWithoutMedication
                            documents={findPeriodsWithoutMedication(documents)}
                            startEdit={startEdit}
                          />
                        ),
                        count: findPeriodsWithoutMedication(documents).length,
                      },
                    ]
                  : []),
              ]}
            </HistoryTabs>
          ) : undefined}
          {editing ? (
            <Form editing={editing} formData={formData} consumeMedID={consumeMedIDRef} documents={documents} />
          ) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

export interface ICollapseStatus {
  collapseID: string;
  setCollapseID: (id: string) => void;
}

interface IOwnProps extends ICapabilityContextProps {
  documents?: Array<IMedication | IMedicationAdverseEvent | IOtherTreatment | IProcedure>;
}

export default withCapabilities(Medication);
